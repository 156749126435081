import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Card, CardMedia, CardActionArea, CircularProgress } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { GET_PRODUCTS } from '../graphql/mutations';
import Footer from './Footer';
import LogoBar from './LogoBar'; // Importez le composant LogoBar

const FlowersPage = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(GET_PRODUCTS);
  const [flowerProducts, setFlowerProducts] = useState([]);

  useEffect(() => {
    if (data && data.products && data.products.items) {
      const flowersArray = data.products.items.filter(product =>
        product.facetValues.some(facetValue => facetValue.code === 'flowers')
      );
      setFlowerProducts(flowersArray);
    }
  }, [data]);

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  ); 
  if (error) return <div>Error loading products</div>;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{ minHeight: '100vh' }} // Full height
    >
      <Helmet>
        <title>KAJIKA - Flowers</title>
        <meta name="description" content="Explore our beautiful collection of Flower." />
      </Helmet>
      <Box>
        <LogoBar /> 
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          sx={{ px: { xs: 2, md: 4 } }} // Padding conditionnel
        >
          {flowerProducts.map((product) => (
            <motion.div
              key={product.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{ width: '100%' }}
            >
              <Card 
                onClick={() => navigate(`/artwork/${product.slug}`)} 
                sx={{ 
                  borderRadius: 2, 
                  boxShadow: 'none', 
                  width: '100%', 
                  marginBottom: 4 
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    sx={{
                      height: '90vh', // Full height
                      width: '100%',  // Full width
                      objectFit: 'cover', // Cover without distortion
                      borderRadius: 2 // Border radius
                    }}
                    image={product.featuredAsset.preview}
                    alt={product.name}
                  />
                </CardActionArea>
              </Card>
            </motion.div>
          ))}
        </Box>
        <Footer />
      </Box>
    </motion.div>
  );
};

export default FlowersPage;
