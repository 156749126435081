import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ORDER_BY_ID } from '../../graphql/queries';
import { Box, Typography, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar, Paper, Button, Container } from '@mui/material';
import LogoBar from '../LogoBar';
import Footer from '../Footer';
import { motion } from 'framer-motion';

const OrderPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(ORDER_BY_ID, { variables: { id } });

  if (loading) return <CircularProgress />;
  if (error) return <Typography variant="body2" color="error">{error.message}</Typography>;

  // Vérification si les données de la commande sont présentes et correctes
  if (!data || !data.order || !data.order.lines) {
    navigate('/404'); // Utilisez le chemin de votre page 404 ici
    return null;
  }

  const order = data.order;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <LogoBar />
      <Container maxWidth="sm" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', my: 4, minHeight:'70vh' }}>
        <Typography variant="h5" gutterBottom align="center">
          Thank you for your purchase!
        </Typography>
        <Paper elevation={0} sx={{ px: 3, py: 2, border: '1px solid #ddd', mt:1, mb: 1  }}>
          <List disablePadding>
          <Typography variant="h6" gutterBottom>
            Order Summary
          </Typography>
            {order.lines.map((line, index) => (
              <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p:0, mb:1}}>
                <ListItemAvatar>
                  <Avatar 
                    src={line.productVariant.product.assets[0]?.preview || "defaultProductImage.jpg"} 
                    alt={line.productVariant.product.name} 
                    sx={{ width: 90, height: 90, borderRadius: '4px', mr:2 }} // Image ronde
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={line.productVariant.product.name}
                  secondary={`Quantity: ${line.quantity}`}
                />
                <Typography variant="body2">
                  {line.linePrice / 100}€
                </Typography>
              </ListItem>
            ))}
            <ListItem sx={{ justifyContent: 'space-between', p:0, mt:2 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                Total with taxes
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {order.totalWithTax / 100}€
              </Typography>
            </ListItem>
          </List>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="contained" onClick={() => navigate('/account')}>
            Go to account
          </Button>
        </Box>
      </Container>
      <Footer />
      </motion.div>
  );
};

export default OrderPage;
