import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { GENERATE_TOKEN } from '../../graphql/queries';
import { ADD_PAYMENT } from '../../graphql/mutations';
import { Box, Button, CircularProgress, Typography, useTheme, useMediaQuery } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const PaymentComponent = ({ order }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [dropinInstance, setDropinInstance] = useState(null);
  const [clientToken, setClientToken] = useState('');
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [getClientToken] = useLazyQuery(GENERATE_TOKEN, {
    onCompleted: data => setClientToken(data.generateBraintreeClientToken),
  });

  const [addPaymentToOrder] = useMutation(ADD_PAYMENT);

  useEffect(() => {
    getClientToken();

    if (clientToken) {
      import('braintree-web-drop-in').then(async (module) => {
        const instance = await module.default.create({
          authorization: clientToken,
          container: '#dropin-container',
          card: {
            cardholderName: {
              required: true,
            },
            overrides: {},
          },
        });

        setDropinInstance(instance);

        if (instance.isPaymentMethodRequestable()) {
          setShowSubmitButton(true);
        }

        instance.on('paymentMethodRequestable', (event) => {
          setShowSubmitButton(true);
        });

        instance.on('noPaymentMethodRequestable', () => {
          console.error('No payment method is available.');
        });
      });
    }
  }, [clientToken, getClientToken]);

  async function submitPayment() {
    if (!dropinInstance || !dropinInstance.isPaymentMethodRequestable()) {
      return;
    }

    setShowSubmitButton(false);
    setProcessing(true);

    const paymentMethod = await dropinInstance.requestPaymentMethod();
    const input = {
      method: 'braintree',
      metadata: paymentMethod,
    };

    addPaymentToOrder({ variables: { input } }).then(response => {
      if (response.data.addPaymentToOrder.__typename === 'Order') {
        console.log('Payment added successfully.');
        navigate(`/order/${response.data.addPaymentToOrder.id}`);
      } else {
        console.error('Error adding payment:', response.data.addPaymentToOrder.message);
      }
      setProcessing(false);
    });
  }

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2, textAlign:'center' }} gutterBottom>
        Secure Checkout
      </Typography>
      <div id="dropin-container" />
      {showSubmitButton && (
        <Button variant="contained" color="primary" onClick={submitPayment} disabled={processing} fullWidth>
          Submit Payment
        </Button>
      )}
      {processing && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={24} />
          <Typography variant="body2" sx={{ marginLeft: 1 }}>Processing...</Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 3 }}>
        <VerifiedUserIcon color="success" />
        <Typography variant="body2" sx={{ marginLeft: 1 }}>
          Payment secured with Paypal
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentComponent;
