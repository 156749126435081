import React, { useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import logo from '../../img/logo.svg';
import { VERIFY_ACCOUNT_MUTATION } from '../../graphql/mutations';
import { motion } from 'framer-motion';

function VerifyPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const [verifyAccount, { data, loading, error }] = useMutation(VERIFY_ACCOUNT_MUTATION, {
        variables: { token },
        onCompleted: data => {
            setTimeout(() => {
                window.location.href = '/account'; // Force full page reload
            }, 5000); // Redirect after 5 seconds
        }
    });

    useEffect(() => {
        verifyAccount();
    }, [verifyAccount]);

    return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.75 }}
        >
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
            <img src={logo} alt="Logo" style={{ width: 150, marginBottom: 30 }} />
        <Box sx={{ border:'1px solid #ddd', textAlign:'center', p:3, borderRadius:'4px' }}>
            {loading && <CircularProgress />}
            {error && <Typography color="error">Error: {error.message}</Typography>}
            {data && (
                <Typography variant="h5" textAlign="center">
                    Successfully verified!
                </Typography>
            )}
                <Typography variant="h6" sx={{ mb:1, mt:2 }} textAlign="center">
                Redirection in progress...
                </Typography>
            <Button variant="contained" color="primary" onClick={() => window.location.href = '/account'} disabled={loading}>
                I can't wait!
            </Button>
        </Box>
        </Box>
        </motion.div>
  );
};


export default VerifyPage;
