import { createContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { USER_ACTIVE } from '../graphql/queries';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(undefined);
    const [cart, setCart] = useState([]);

    const { data, error } = useQuery(USER_ACTIVE);

    useEffect(() => {
        if (data && !error) {
            setUser(data);
        }
    }, [data, error]);

    useEffect(() => {
        console.log('User status:', user ? 'Logged in' : 'Not logged in');
    }, [user]);

    const totalQuantity = (cart) => {
        if (cart === null || cart === undefined || cart.length === 0) {
            return 0;
        } else {
            return cart.lines.reduce((acc, item) => acc + item.quantity, 0);
        }
    };

    return (
        <UserContext.Provider value={{
            user,
            setUser,
            totalQuantity,
            cart,
            setCart,
        }}>
            {children}
        </UserContext.Provider>
    );
};
