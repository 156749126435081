import { gql } from '@apollo/client'
import { CART_FRAGMENT, PAYMENTS } from './fragments'

export const VERIFY_ACCOUNT_MUTATION = gql`
mutation verifyCustomerAccount($token: String!) {
    verifyCustomerAccount(token: $token) {
        __typename
        ... on CurrentUser {
            id
            identifier
        }
        ... on VerificationTokenInvalidError {
            message
        }
        ... on VerificationTokenExpiredError {
            message
        }
        ... on MissingPasswordError {
            message
        }
        ... on PasswordAlreadySetError {
            message
        }
        ... on NativeAuthStrategyError {
            message
        }
    }
}
`

export const USER_LOGIN = gql`
    mutation login($user: String!, $password: String!, $rememberMe: Boolean) {
        login(username: $user, password: $password, rememberMe: $rememberMe) {
            __typename
            ... on CurrentUser {
                id
                identifier
                channels {
                    id
                    token
                    code
                }
            }
            ... on InvalidCredentialsError {
                authenticationError
                message
            }
            ... on NotVerifiedError {
                errorCode
                message
            }
            ... on NativeAuthStrategyError {
                message
            }
        }
    }
`
export const USER_REGISTER = gql`
    mutation Register($data: RegisterCustomerInput!) {
        registerCustomerAccount(input: $data) {
            __typename
            ... on Success {
                success
            }
            ... on ErrorResult {
                errorCode
                message
            }
        }
    }
`

export const USER_LOGOUT = gql`
    mutation {
        logout {
            success
            __typename
        }
    }
`
export const USER_REQUEST_RESET_PASSWORD = gql`
    mutation requestPasswordReset($email: String!) {
        requestPasswordReset(emailAddress: $email) {
            __typename
        }
    }
`
export const USER_RESET_PASSWORD = `
mutation resetPassword($token:String!, $password: String!) {
    resetPassword(token:$token, password:$password) {
     __typename
    }
  }`

export const ADD_ITEM_CART = gql`
    ${CART_FRAGMENT}
    mutation addItem($productId: ID!, $quantity: Int!) {
        addItemToOrder(productVariantId: $productId, quantity: $quantity) {
            __typename
            ... on InsufficientStockError {
                quantityAvailable
                message
                order {
                    ...Cart
                }
            }
            ... on ErrorResult {
                errorCode
                message
            }
        }
    }
`
export const CHANGE_QTY_ITEM_CART = gql`
    mutation adjustOrderLine($orderLineId: ID!, $quantity: Int!) {
        adjustOrderLine(orderLineId: $orderLineId, quantity: $quantity) {
            __typename
        }
    }
`
export const REMOVE_ITEM_CART = gql`
    mutation removeOrderLine($orderLineId: ID!) {
        removeOrderLine(orderLineId: $orderLineId) {
            __typename
        }
    }
`

export const EMPTY_CART = gql`
    mutation removeAllOrderLines {
        removeAllOrderLines {
            __typename
        }
    }
`
export const SET_CUSTOMER_ORDER = gql`
    mutation setCustomerForOrder($input: CreateCustomerInput!) {
        setCustomerForOrder(input: $input) {
            __typename
        }
    }
`
export const SET_ADDRESSES_ORDER = gql`
    ${CART_FRAGMENT}
    mutation setShipping($input: CreateAddressInput!) {
        setOrderShippingAddress(input: $input) {
            __typename
            ...Cart
        }
    }
`
export const SET_ORDER_SHIPPING_ADDRESS = gql`
  mutation SetOrderShippingAddress($input: CreateAddressInput!) {
    setOrderShippingAddress(input: $input) {
      ... on Order {
        id
        shippingAddress {
          fullName
          company
          streetLine1
          streetLine2
          city
          province
          postalCode
          countryCode
          phoneNumber
        }
      }
    }
  }
`

export const CHANGE_STATE_ORDER = gql`
    mutation transitionOrderToState($state: String!) {
        transitionOrderToState(state: $state) {
            __typename
        }
    }
`

export const CUSTOMER_UPDATE = gql`
    mutation updateCustomer($input: UpdateCustomerInput!) {
        updateCustomer(input: $input) {
            id
            firstName
            lastName
            phoneNumber
            emailAddress
        }
    }
`

export const CUSTOMER_ADDRESS_UPDATE = gql`
    mutation updateAddressesCustomer($input: UpdateAddressInput!) {
        updateCustomerAddress(input: $input) {
            id
            streetLine1
            streetLine2
            city
            province
            postalCode
            phoneNumber
            defaultShippingAddress
            defaultBillingAddress
        }
    }
`

export const USER_PASSWORD_UPDATE = gql`
    mutation updateCustomerPassword(
        $currentPassword: String!
        $newPassword: String!
    ) {
        updateCustomerPassword(
            currentPassword: $currentPassword
            newPassword: $newPassword
        ) {
            __typename
            ... on Success {
                success
            }
            ... on InvalidCredentialsError {
                authenticationError
                message
            }
        }
    }
`

// Mutations customs

export const GET_PRODUCT_DETAIL = gql`
query GetProductDetail($slug: String!) {
  product(slug: $slug) {
    id
    name
    description
    facetValues {
    id
    code
    name
    facet {
    id
    code
    name
    }
    }
    featuredAsset {
      preview
    }
    assets {
      id
      preview
    }
    variants {
      id
      name
      price
    }
  }
}
`

export const GET_ACTIVE_ORDER = gql`
  query GetActiveOrder {
    activeOrder {
      id
      lines {
        id
        quantity
        productVariant {
          id
          name
          priceWithTax
          product {
            featuredAsset {
              preview
            }
          }
        }
      }
      totalWithTax
    }
  }
`

export const GET_PRODUCTS = gql`
  query GetProducts {
    products {
      items {
        id
        name
        slug
        collections {
          slug
        }
        featuredAsset {
          preview
        }
        facetValues {
          code
        }
      }
    }
  }
`

export const ASSIGN_SHIPPING_METHOD_TO_ORDER = gql`
  mutation SetShippingMethod($id: [ID!]!) {
    setOrderShippingMethod(shippingMethodId: $id) {
      ...on Order {
        id
      }
      ...on ErrorResult {
        errorCode
        message
      }
    }
  }
`

export const TRANSITION_TO_STATE = gql`
  mutation TransitionToState($state: String!) {
      transitionOrderToState(state: $state) {
        ...on Order {
          id
        }
        ...on ErrorResult {
          errorCode
          message
        }
      }
  }  
`

export const SET_PAYMENT_METHOD_ORDER = gql`
mutation AddPaymentToOrder($input: PaymentInput!) {
  addPaymentToOrder(input: $input) {
    ... on Order {
      id
      code
    }
    ... on OrderPaymentStateError {
      errorCode
      message
    }
  }
}
`

export const CREATE_PAYMENT_INTENT = gql`
  mutation CreatePaymentIntent($amount: Int!) {
    createStripePaymentIntent(amount: $amount) {
      clientSecret
    }
  }
`
export const CREATE_MOLLIE_PAYMENT_INTENT = gql`
mutation CreateMolliePaymentIntent {
    createMolliePaymentIntent(input: {
      redirectUrl: "http://localhost:3000/order"
      paymentMethodCode: "mollie-payment-method"
      molliePaymentMethodCode: "ideal"
    }) {
           ... on MolliePaymentIntent {
                url
            }
           ... on MolliePaymentIntentError {
                errorCode
                message
           }
    }
  }
`
export const ADD_PAYMENT = gql`
  mutation AddPayment($input: PaymentInput!) {
    addPaymentToOrder(input: $input) {
      ... on Order {
        id
        payments {
          id
          amount
          errorMessage
          method
          state
          transactionId
          createdAt
        }
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`