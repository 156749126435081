import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Box, Typography, Card, CardMedia, Button, Grid, useMediaQuery, useTheme, CircularProgress, Dialog } from '@mui/material';
import { motion } from 'framer-motion';
import DOMPurify from 'dompurify';

import PrevBar from './PrevBar';
import Footer from './Footer';
import { useCart } from '../context/CartContext';
import { GET_PRODUCT_DETAIL, ADD_ITEM_CART, TRANSITION_TO_STATE, GET_ACTIVE_ORDER } from '../graphql/mutations';
import ContactForm from './ContactForm'; // Assurez-vous que ce chemin est correct

const ArtworkPage = () => {
  const { slug } = useParams();
const { data, loading, error } = useQuery(GET_PRODUCT_DETAIL, {
  variables: { slug },
  fetchPolicy: "network-only" // Forcer le chargement des données depuis le réseau uniquement
});
  const [addItemToOrder] = useMutation(ADD_ITEM_CART);
  const [transitionToState] = useMutation(TRANSITION_TO_STATE);
  const { toggleCart } = useCart();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isContactDialogOpen, setIsContactDialogOpen] = React.useState(false);

  const handleBuyNow = async () => {
    if (data && data.product) {
      const productVariantId = data.product.variants[0].id;
      const quantity = 1;
  
      try {
        await transitionToState({
          variables: {
            state: "AddingItems"
          },
        });
        const response = await addItemToOrder({
          variables: {
            productId: productVariantId,
            quantity,
          },
          refetchQueries: [{ query: GET_ACTIVE_ORDER }] 
        });
        if (response.data.addItemToOrder.__typename === "Order") {
          console.log("About to toggle cart");
          toggleCart(true);
          console.log("Cart toggled");
        } else if (response.data.addItemToOrder.__typename === "InsufficientStockError") {
          console.error('Insufficient stock:', response.data.addItemToOrder.message);
          alert('Only partial items were added due to stock limits.');
        } else if (response.data.addItemToOrder.__typename === "ErrorResult") {
          console.error('Error:', response.data.addItemToOrder.message);
          alert('Failed to add the item to the cart. Please check the details and try again.');
        }
      } catch (error) {
        console.error('Error adding item to order:', error);
        alert('An error occurred while adding the item to the cart. Please try again.');
      }
    }
  };  

  const handleDiscuss = () => {
    setIsContactDialogOpen(true);
  };

  const renderButtonBasedOnFacet = () => {
    if (data && data.product && data.product.facetValues) {
      const facetCodes = data.product.facetValues.map(facetValue => facetValue.code);
      if (facetCodes.includes('direct-sale')) {
        return <Button variant="contained" sx={{ mt: 2 }} onClick={handleBuyNow}>Buy Now</Button>;
      } else if (facetCodes.includes('discussion')) {
        return <Button variant="contained" sx={{ mt: 2 }} onClick={handleDiscuss}>Let's Discuss</Button>;
      } else if (facetCodes.includes('unavailable')) {
        return <Button variant="contained" disabled sx={{ mt: 2 }}>Already Taken</Button>;
      }
    }
    return <Button variant="contained" disabled sx={{ mt: 2 }}>Already Taken</Button>;
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  );
  if (error) return <div>Error loading product details</div>;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <PrevBar />
      <Box sx={{ p: { xs: 2, sm: 3 }, pt: { xs: '56px', sm: '64px' } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: { xs: '10vh', sm: '0' } }}>
              {data.product.assets.map((asset) => (
                <Card key={asset.id} sx={{ boxShadow: 'none' }}>
                  <CardMedia
                    component="img"
                    image={asset.preview}
                    alt={`Product Image ${asset.id}`}
                    sx={{ width: '100%' }}
                  />
                </Card>
              ))}
            </Box>
          </Grid>
          {isMobile ? (
          <Grid  sx={{
            position: 'fixed',
            bottom: '0',
            background: 'white',
            height: 'fit-content',
            width: '100vw',
            borderTop: '1px solid #ddd',
            pt: 2,
            pb: 4,
            pl: 2,
            pr: 2,
            zIndex:10
          }}>
            <Box>
              <Typography variant="h4" sx={{ mb: 0 }}>{data.product.name}</Typography>
              <Typography variant="body1" sx={{ mt: 1 }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.product.description) }}></Typography>
              {renderButtonBasedOnFacet()}
            </Box>
          </Grid>          ) : (
          <Grid item md={5} sx={{
            position: 'sticky',
            top: '10vh',
            height: 'fit-content',
          }}>
            <Box sx={{ px: 2, pt: '22vh' }}>
              <Typography variant="h3" sx={{ mb: 0 }}>{data.product.name}</Typography>
              <Typography variant="body1" sx={{ mt: 1 }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.product.description) }}></Typography>
              {renderButtonBasedOnFacet()}
            </Box>
          </Grid>          )}
        </Grid>
      </Box>
      <Dialog
        open={isContactDialogOpen}
        onClose={() => setIsContactDialogOpen(false)}
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{
          sx: {
            paddingY: 2,
            paddingX: 3,
          },
        }}
      >
        <ContactForm onSubmitSuccess={() => setIsContactDialogOpen(false)} />
      </Dialog>
      <Footer />
    </motion.div>
  );
};

export default ArtworkPage;
