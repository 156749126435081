import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { USER_REQUEST_RESET_PASSWORD } from '../../graphql/mutations';
import logo from '../../img/logo.svg'; // Assurez-vous du chemin d'accès au logo
import { motion } from 'framer-motion';

function RequestResetPage() {
    const [email, setEmail] = useState('');
    const [requestReset, { loading, data, error }] = useMutation(USER_REQUEST_RESET_PASSWORD);

    const handleRequestReset = async () => {
        await requestReset({
            variables: { email }
        });
    };

    return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.75 }}
        >
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
            <img src={logo} alt="Logo" style={{ width: 150, marginBottom: 20 }} />
            <Box sx={{ textAlign:'center', border:'1px solid #ddd', borderRadius:'4px', p:4 }}>
            <Typography variant="h5">Request Password Reset</Typography>
            <TextField
                label="Email Address"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                margin="normal"
                required
                fullWidth
            />
            <Button variant="contained" fullWidth color="primary" onClick={handleRequestReset} disabled={loading} sx={{ mt: 1 }}>
                {loading ? <CircularProgress size={24} /> : "Request Reset"}
            </Button>
            {data && <Typography>Password reset link sent!</Typography>}
            {error && <Typography color="error">{error.message}</Typography>}
            </Box>
            <Button  color="primary" onClick={() => window.location.href = '/checkout'} disabled={loading} sx={{ mt: 1 }}>
                Back
            </Button>
        </Box>
        </motion.div>
  );
};

export default RequestResetPage;
