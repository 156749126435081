import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Snackbar, Alert } from '@mui/material';

// Function to check if the email is valid
const isValidEmail = email => {
  return /\S+@\S+\.\S+/.test(email);
};

export default function ContactForm({ onSubmitSuccess }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Close the Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    if(snackbarSeverity === "success" && onSubmitSuccess) {
      onSubmitSuccess(); // Close the modal on success after the snackbar closes
      setName('');
      setEmail('');
      setMessage('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check that all fields are filled and the email format is valid
    if (!name || !email || !message || !isValidEmail(email)) {
      setSnackbarMessage("Please fill all fields correctly.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    const response = await fetch('https://api.kajitest.fr/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, message }),
    });

    if (response.ok) {
      setSnackbarMessage("Message sent successfully!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } else {
      const errorMsg = await response.text();
      setSnackbarMessage(errorMsg || "Error sending the message.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
        <Typography variant="h4" sx={{ mb: 1 }}>こんにちは♡</Typography>
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          error={!isValidEmail(email) && email.length > 0}
          helperText={!isValidEmail(email) && email.length > 0 ? "Invalid email" : ""}
        />
        <TextField
          label="Tell me everything"
          multiline
          rows={4}
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          required
        />
        <Button type="submit" variant="contained" sx={{ mt: 1 }}>Send</Button>
      </Box>
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
