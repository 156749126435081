import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box, useTheme, useMediaQuery, Slide, Button, Dialog, Drawer } from '@mui/material';
import mailIconSvg from '../img/mail.svg';
import ContactForm from './ContactForm';
import logo from '../img/logo.svg';
import cartIconSvg from '../img/cart.svg';
import menuIconSvg from '../img/menu.svg';
import CartPage from './CartPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCart } from '../context/CartContext';

export default function PrevBar() {
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isCartOpen, toggleCart } = useCart();

  const toggleContactDialog = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsContactDialogOpen(open);
  };

  const goBack = () => {
    navigate(-1);
  };

  const goHomePage = () => {
    navigate('/');
  };

  return (
    <>
      <AppBar position="fixed" elevation={0} color="background">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <IconButton color="inherit" aria-label="back" onClick={goBack} size="small">
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            <Box component="span"
                 sx={{ fontFamily: 'Manrope', fontWeight: 'bold', textTransform: 'uppercase', fontSize: '0.75rem', cursor: 'pointer' }}
                 onClick={goBack}>
              All
            </Box>
          </Box>

          <Box sx={{ flexGrow: 0, cursor: 'pointer' }} onClick={goHomePage}>
            <img src={logo} alt="Logo" style={{ height: '25px' }} />
          </Box>

          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          </Box>
        </Toolbar>
      </AppBar>
      <Dialog
        open={isContactDialogOpen}
        onClose={toggleContactDialog(false)}
        fullWidth={true}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: isMobile ? '90vw' : '35vw',
            paddingY: isMobile ? 1 : 3,
            paddingX: isMobile ? 1 : 3,
          },
        }}
      >
        <ContactForm onSubmitSuccess={toggleContactDialog(false)} />
      </Dialog>
    </>
  );
}
