import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { AppBar, Toolbar, IconButton, Box, useTheme, useMediaQuery, Slide, Button, Dialog, Drawer } from '@mui/material';
import mailIconSvg from '../img/mail.svg'; 
import ContactForm from './ContactForm'; 
import logo from '../img/logo.svg'; 
import cartIconSvg from '../img/cart.svg'; 
import menuIconSvg from '../img/menu.svg'; 
import CartPage from './CartPage'; 

export default function LogoBar() {
  const [showMenu, setShowMenu] = useState(false);
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleContactDialog = (open) => () => {
    setIsContactDialogOpen(open);
  };

  const handleAllClick = () => {
    navigate('/');
  };

  const goToGalleryPage = () => {
    navigate('/gallery');
  };

  const goToFlowers = () => {
    navigate('/flowers');
  };

  const goToMonstersPage = () => {
    navigate('/monsters');
  };

  const goToAboutPage = () => {
    navigate('/about'); 
  };
 
  const toggleCartDrawer = (open) => () => {
    setIsCartDrawerOpen(open);
  };

  return (
    <>
      <AppBar position="static" elevation={0} color="background">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
            <IconButton aria-label="menu" onClick={() => setShowMenu(!showMenu)}>
              <img src={menuIconSvg} alt="Menu" style={{ height: '30px' }} />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 0, cursor:'pointer' }} onClick={handleAllClick}>
            <img src={logo} alt="Logo" style={{ height: '25px' }} />
          </Box>

          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          </Box>
        </Toolbar>
        <Slide direction="down" in={showMenu} mountOnEnter unmountOnExit>
          <Box sx={{
              display: 'flex', 
              justifyContent: 'center', 
              verticalAlign: 'middle',
              bgcolor: 'background.paper', 
              position: 'absolute', 
              top: 0,
              left: '20%',
              width: '60%',
              height: { xs: '56px', sm: '64px' }
          }}>
              <Button color="inherit" sx={{ fontFamily: 'Manrope', fontWeight: 'bold', textTransform: 'uppercase' }} onClick={goToFlowers}>Flowers</Button>
              <Button color="inherit" sx={{ fontFamily: 'Manrope', fontWeight: 'bold', textTransform: 'uppercase' }} onClick={goToMonstersPage}>Monsters</Button>
              <Button color="inherit" sx={{ fontFamily: 'Manrope', fontWeight: 'bold', textTransform: 'uppercase' }} onClick={goToAboutPage}>About</Button>
          </Box>
        </Slide>
      </AppBar>
      <Dialog
        open={isContactDialogOpen}
        onClose={toggleContactDialog(false)}
        fullWidth={true}
        maxWidth={false} // Désactiver la largeur maximale par défaut
        PaperProps={{
          sx: {
            width: isMobile ? '90vw' : '35vw', // Largeur conditionnelle
            paddingY: isMobile ? 1 : 3, // Padding conditionnel vertical
            paddingX: isMobile ? 1 : 3, // Padding conditionnel horizontal
          },
        }}
      >
        <ContactForm onSubmitSuccess={toggleContactDialog(false)} />
      </Dialog>
    </>
  );
}
