import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ACTIVE_ORDER_DETAILS } from '../../graphql/queries';
import { SET_ORDER_SHIPPING_ADDRESS } from '../../graphql/mutations';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid // Ajout de Grid
} from '@mui/material';

const AddressPage = ({ onNext }) => {

  const [address, setAddress] = useState({
    fullName: '',
    company: '',
    streetLine1: '',
    streetLine2: '',
    city: '',
    province: '',
    postalCode: '',
    countryCode: 'FR',
    phoneNumber: '',
    defaultShippingAddress: false,
    defaultBillingAddress: false,
  });

  const { data, loading, error } = useQuery(GET_ACTIVE_ORDER_DETAILS);
  const [setOrderShippingAddress, mutationStatus] = useMutation(SET_ORDER_SHIPPING_ADDRESS);

  useEffect(() => {
    if (data && data.activeOrder && data.activeOrder.shippingAddress) {
      setAddress({
        fullName: data.activeOrder.shippingAddress.fullName || '',
        company: data.activeOrder.shippingAddress.company || '',
        streetLine1: data.activeOrder.shippingAddress.streetLine1 || '',
        streetLine2: data.activeOrder.shippingAddress.streetLine2 || '',
        city: data.activeOrder.shippingAddress.city || '',
        province: data.activeOrder.shippingAddress.province || '',
        postalCode: data.activeOrder.shippingAddress.postalCode || '',
        countryCode: data.activeOrder.shippingAddress.countryCode || 'FR',
        phoneNumber: data.activeOrder.shippingAddress.phoneNumber || '',
        defaultShippingAddress: false,
        defaultBillingAddress: false,
      });
    }
  }, [data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddress(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { __typename, ...addressInput } = address;
  
    try {
      await setOrderShippingAddress({
        variables: {
          input: addressInput
        }
      });
      onNext();
    } catch (error) {
      console.error("Error setting shipping address", error);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading address data.</Typography>;

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <Typography variant="h4" gutterBottom>
        Shipping Address
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="fullName"
            label="Full Name"
            name="fullName"
            autoComplete="name"
            autoFocus
            value={address.fullName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="normal"
            fullWidth
            required
            id="streetLine1"
            label="Street Address"
            name="streetLine1"
            autoComplete="street-address"
            value={address.streetLine1}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="normal"
            fullWidth
            id="streetLine2"
            label="Apartment, suite, etc. (optional)"
            name="streetLine2"
            value={address.streetLine2}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="city"
            label="City"
            name="city"
            value={address.city}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="postalCode"
            label="ZIP / Postal Code"
            name="postalCode"
            value={address.postalCode}
            onChange={handleInputChange}
          />
        </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel required id="country-code-label">Country</InputLabel>
            <Select
              labelId="country-code-label"
              id="countryCode"
              required
              name="countryCode"
              value={address.countryCode || 'FR'}
              label="Country"
              onChange={handleInputChange}
            >
                <MenuItem value="FR">France</MenuItem>
                <MenuItem value="BE">Belgique</MenuItem>
                <MenuItem value="CH">Suisse</MenuItem>
                <MenuItem value="CA">Canada</MenuItem>
                <MenuItem value="US">États-Unis</MenuItem>
                <MenuItem value="DE">Allemagne</MenuItem>
                <MenuItem value="IT">Italie</MenuItem>
                <MenuItem value="GB">Royaume-Uni</MenuItem>
                <MenuItem value="ES">Espagne</MenuItem>
                <MenuItem value="NL">Pays-Bas</MenuItem>
                <MenuItem value="AT">Autriche</MenuItem>
                <MenuItem value="PT">Portugal</MenuItem>
                <MenuItem value="SE">Suède</MenuItem>
                <MenuItem value="BR">Brésil</MenuItem>
                <MenuItem value="AU">Australie</MenuItem>
                <MenuItem value="JP">Japon</MenuItem>
                <MenuItem value="CN">Chine</MenuItem>
                <MenuItem value="RU">Russie</MenuItem>
                <MenuItem value="MX">Mexique</MenuItem>
                <MenuItem value="NO">Norvège</MenuItem>
                <MenuItem value="DK">Danemark</MenuItem>
                <MenuItem value="FI">Finlande</MenuItem>
                <MenuItem value="PL">Pologne</MenuItem>
                <MenuItem value="IE">Irlande</MenuItem>
                <MenuItem value="GR">Grèce</MenuItem>
                <MenuItem value="HU">Hongrie</MenuItem>
                <MenuItem value="CZ">République tchèque</MenuItem>
                <MenuItem value="RO">Roumanie</MenuItem>
                <MenuItem value="AR">Argentine</MenuItem>
                <MenuItem value="CL">Chili</MenuItem>
                <MenuItem value="CO">Colombie</MenuItem>
                <MenuItem value="PE">Pérou</MenuItem>
                <MenuItem value="ZA">Afrique du Sud</MenuItem>
                <MenuItem value="KR">Corée du Sud</MenuItem>
                <MenuItem value="TW">Taïwan</MenuItem>
                <MenuItem value="SG">Singapour</MenuItem>
                <MenuItem value="HK">Hong Kong</MenuItem>
                <MenuItem value="MY">Malaisie</MenuItem>
                <MenuItem value="TH">Thaïlande</MenuItem>
                <MenuItem value="ID">Indonésie</MenuItem>
                <MenuItem value="PH">Philippines</MenuItem>
                <MenuItem value="IN">Inde</MenuItem>
                <MenuItem value="AE">Émirats arabes unis</MenuItem>
                <MenuItem value="SA">Arabie saoudite</MenuItem>
                <MenuItem value="QA">Qatar</MenuItem>
                <MenuItem value="KW">Koweït</MenuItem>
                <MenuItem value="OM">Oman</MenuItem>
                <MenuItem value="BH">Bahreïn</MenuItem>
                <MenuItem value="EG">Égypte</MenuItem>
                <MenuItem value="DZ">Algérie</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            name="phoneNumber"
            autoComplete="tel"
            value={address.phoneNumber}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 0.5 }}
            disabled={loading}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressPage;
