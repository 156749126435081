import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid, Card, CardMedia, CardActionArea, CircularProgress } from '@mui/material';
import LogoBar from './LogoBar';
import ImageFilter from './ImageFilter';
import logo from '../img/logo.svg';
import Footer from './Footer';
import { useQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { GET_PRODUCTS } from '../graphql/mutations';

const GalleryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState('all');
  const { data, loading, error } = useQuery(GET_PRODUCTS);
  const [shuffledProducts, setShuffledProducts] = useState([]);

  useEffect(() => {
    if (data && data.products && data.products.items) {
      // Mélanger les produits une seule fois lorsque nous recevons les données initiales
      const shuffledArray = shuffleArray(data.products.items);
      setShuffledProducts(shuffledArray);
    }
  }, [data]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    // Défilement immédiat vers 'artwork-section' lors du changement de filtre
    const section = document.getElementById('artwork-section');
    if (section) {
      window.scrollTo({ top: section.offsetTop, behavior: 'smooth' });
    }
  };

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const filteredProducts = React.useMemo(() => {
    return shuffledProducts.filter(product =>
      !product.facetValues.some(facetValue => facetValue.code === 'flowers') &&
      (filter === 'all' || product.facetValues.some(facetValue => facetValue.code === filter))
    );
  }, [shuffledProducts, filter]);

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  ); 
  if (error) return <div>Error loading order details</div>;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>KAJIKA - Gallery</title>
        <meta name="description" content="Explore our magnificent collection of artworks." />
      </Helmet>
      <Box>
        <LogoBar />
        <Grid container spacing={2} sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 0, sm: 0 } }} id="artwork-section" key={filter}>
        {filteredProducts.map((product) => (
          <Grid item xs={12} sm={4} key={product.id}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Card elevation={0} onClick={() => navigate(`/artwork/${product.slug}`)}>
                <CardActionArea>
                <CardMedia
                    component="img"
                    sx={{
                      height: 1, // Prend toute la hauteur disponible
                      width: 1,  // Prend toute la largeur disponible
                      objectFit: 'cover' // Assure que l'image couvre toute la surface sans déformation
                    }}
                    image={product.featuredAsset.preview}
                    alt={product.name}
                  />
                </CardActionArea>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
      <ImageFilter currentFilter={filter} onFilterChange={handleFilterChange} alwaysVisible={true} />
        <Footer />
      </Box>
    </motion.div>
  );
};

export default GalleryPage;
