import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Container, Card, CardContent, TextField, Button, Typography, Link, Box, Snackbar, Alert, Grid } from '@mui/material';
import { USER_REGISTER } from '../../graphql/mutations';
import { UserContext } from '../../context/UserContext';  // Ensure the import path is correct
import logo from '../../img/logo.svg';
import { motion } from 'framer-motion';

const RegisterPage = () => {
    const { user } = useContext(UserContext);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        password: '',
        confirmPassword: ''
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const navigate = useNavigate();
    const [registerUser, { loading, error }] = useMutation(USER_REGISTER);
    const [passwordError, setPasswordError] = useState('');

    useEffect(() => {
        if (user) {  // Check if user.id exists to determine if the user is logged in
            navigate('/account');  // Redirect to the home page or another appropriate page
        }
    }, [user, navigate]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));

        if (name === 'password') {
            const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
            if (!passwordRegex.test(value)) {
                setPasswordError('Password must be at least 8 characters and include one uppercase letter, one lowercase letter, one number, and one special character.');
            } else {
                setPasswordError('');
            }
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            setSnackbarMessage("Passwords do not match.");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }

        if (passwordError) {
            setSnackbarMessage(passwordError);
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }
        
        try {
            const { data } = await registerUser({
                variables: {
                    data: {
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        emailAddress: formData.emailAddress,
                        password: formData.password
                    }
                }
            });
    
            if (data.registerCustomerAccount.__typename !== 'ErrorResult') {
                setSnackbarMessage('Registration successful!');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                setTimeout(() => {
                    navigate('/login');
                }, 1500);
            } else {
                setSnackbarMessage(data.registerCustomerAccount.message);
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        } catch (err) {
            setSnackbarMessage('Registration failed!');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            console.error('Error registering user:', err);
        }
    };


    const goToGalleryPage = () => {
        navigate('/gallery');
      };
    

      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.75 }}
        >
        <Container maxWidth="sm">
      <Grid onClick={goToGalleryPage} item xs={12} textAlign="center" sx={{ mt:4, mb:1.5, cursor:'pointer' }}>
        <img src={logo} alt="Logo" style={{ height: '25px' }} />
      </Grid>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="85vh">
                <Card elevation={0} sx={{ border:'1px solid #ddd', borderRadius:'4px' }}>
                    <CardContent >
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="h5" component="h2" gutterBottom>
                                Register
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    required
                                    label="First Name"
                                    name="firstName"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={formData.firstName}
                                />
                                <TextField
                                    required
                                    label="Last Name"
                                    name="lastName"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={formData.lastName}
                                />
                                <TextField
                                    required
                                    label="Email Address"
                                    type="email"
                                    name="emailAddress"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={formData.emailAddress}
                                />
                                <TextField
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    required
                                    label="Password"
                                    type="password"
                                    name="password"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={formData.password}
                                />
                                <TextField
                                    required
                                    label="Confirm Password"
                                    type="password"
                                    name="confirmPassword"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={formData.confirmPassword}
                                />
                                <Button type="submit" color="primary" variant="contained" fullWidth sx={{ mt: 2 }}>
                                    Register
                                </Button>
                                <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                                    Already have an account? <Link href="/checkout" underline="hover">Login</Link>
                                </Typography>
                            </form>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
        </motion.div>
  );
};

export default RegisterPage;