import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { throttle } from 'throttle-debounce';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_BACKEND_URL,
    credentials: 'include',
});

const rateLimitLink = new ApolloLink((operation, forward) => {
    return new Observable(observer => {
        const handle = setTimeout(() => {
            forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
            });
        }, 50); // Limite à 20 requêtes par seconde (1000 ms / 20 = 50 ms par requête)

        return () => {
            clearTimeout(handle);
        };
    });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([rateLimitLink, errorLink, httpLink]);

const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
});

export default client;
