import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [isCartOpen, setCartOpen] = useState(false);

  const toggleCart = (open) => {
    console.log("Toggling cart to: ", open); // Log pour vérifier l'appel
    setCartOpen(open);
  };

  useEffect(() => {
    console.log("Cart drawer state is now: ", isCartOpen); // Vérifier le changement d'état
  }, [isCartOpen]);

  return (
    <CartContext.Provider value={{ isCartOpen, toggleCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
