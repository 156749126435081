import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { ApolloProvider } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import { UserProvider } from './context/UserContext';  
import { CartProvider } from './context/CartContext';

import apolloClient from './ApolloClient';
import theme from './theme';
import './App.css';

import HomePage from './components/HomePage';
import ArtworkPage from './components/ArtworkPage';
import GalleryPage from './components/GalleryPage';
import AboutPage from './components/AboutPage';
import TermsOfServicePage from './components/TermsOfServicePage';
import RegisterPage from './components/User/RegisterPage';
import AccountValidationPage from './components/User/AccountValidationPage';
import ProfilePage from './components/User/ProfilePage';
import VerifyPage from './components/User/VerifyPage';
import CheckoutPage from './components/Checkout/CheckoutPage';
import OrderPage from './components/User/OrderPage'; 
import RequestResetPage from './components/User/RequestResetPage'; 
import ResetPasswordPage from './components/User/ResetPasswordPage'; 
import LoginPage from './components/User/LoginPage';
import NotFoundPage from './components/NotFoundPage'; 
import MonstersPage from './components/Monsters/MonstersPage'; 
import ImieiamoriPage from './components/Monsters/ImieiamoriPage'; 
import LittlemermaidPage from './components/Monsters/LittlemermaidPage'; 
import FlowersPage from './components/FlowersPage'; 


function AppWithAnimatePresence() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />

        <Route path="*" element={<NotFoundPage />} />

        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/artwork/:slug" element={<ArtworkPage />} />

        <Route path="/about" element={<AboutPage />} />

        <Route path="/terms" element={<TermsOfServicePage />} />

        <Route path="/flowers" element={<FlowersPage />} />

        <Route path="/monsters" element={<MonstersPage />} />
        <Route path="/monsters/imieiamori" element={<ImieiamoriPage />} />
        <Route path="/monsters/littlemermaid" element={<LittlemermaidPage />} />

        <Route path="/register" element={<RegisterPage />} />
        <Route path="/register/mail" element={<AccountValidationPage />} />

        <Route path="/login" element={<LoginPage />} />

        <Route path="/account/verify" element={<VerifyPage />} />
        <Route path="/account" element={<ProfilePage />} />
        <Route path="/account/verify" element={<VerifyPage />} />
        <Route path="/account/request-password" element={<RequestResetPage />} />
        <Route path="/account/reset-password" element={<ResetPasswordPage />} />
        <Route path="/order/:id" element={<OrderPage />} />

        <Route path="/checkout/" element={<CheckoutPage />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <CartProvider> 
            <Router>
                <AppWithAnimatePresence />
            </Router>
          </CartProvider>
        </UserProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
