import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useQuery } from '@apollo/client';
import { GET_ACTIVE_ORDER_DETAILS } from '../../graphql/queries';
import { Stepper, Step, StepLabel, Box, Button, Grid, Typography, CircularProgress } from '@mui/material';
import { UserContext } from '../../context/UserContext';
import MustLoginPage from '../Checkout/MustLoginPage';
import AddressPage from './AddressPage';
import ShippingPage from './ShippingPage';
import PaymentPage from './PaymentPage';
import logo from '../../img/logo.svg';
import { motion } from 'framer-motion';

const CheckoutPage = () => {
  const { user } = useContext(UserContext);
  const { data, loading, error } = useQuery(GET_ACTIVE_ORDER_DETAILS);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.activeOrder && data.activeOrder.shippingLines.length > 0) {
      setSelectedShippingMethod(data.activeOrder.shippingLines[0].shippingMethod.id);
    }
  }, [data]);

  const handleShippingMethodChange = (methodId) => {
    setSelectedShippingMethod(methodId);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <AddressPage onNext={handleNext} />;
      case 1:
        return (
          <ShippingPage
            selectedShippingMethod={selectedShippingMethod}
            onShippingMethodChange={handleShippingMethodChange}
            onNext={handleNext}
          />
        );
      case 2:
        return <PaymentPage />;
      default:
        return 'Unknown step';
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const goToGalleryPage = () => {
    navigate('/gallery');
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  ); 
  if (error) return <div>Error loading order details</div>;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
    <Box p={2} mx="auto"  maxWidth="600px">
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid onClick={goToGalleryPage} item xs={12} textAlign="center" sx={{ mt:2, mb:2, cursor:'pointer' }}>
          <img src={logo} alt="Logo" style={{ height: '25px' }} />
        </Grid>
        <Grid item xs={12}> 
          {user ? (
            <Box>
              <Stepper sx={{ border:'1px solid #ddd', p: 2, mb: 3, borderRadius:'4px' }} activeStep={activeStep}>
                {['Address', 'Shipping', 'Payment'].map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box sx={{ textAlign:'center'}} >
                {activeStep === 3 ? (
                  <Typography variant="body1" align="center">
                    All steps completed - you're finished
                  </Typography>
                ) : (
                  <>
                    {getStepContent(activeStep)}
                    <Button fullWidth disabled={activeStep === 0} onClick={handleBack} sx={{ mt: 1 }}>
                      Back
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          ) : (
            <MustLoginPage />
          )}
        </Grid>
      </Grid>
    </Box>
    </motion.div>
  );
};

export default CheckoutPage;
