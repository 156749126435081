import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../../img/logo.svg'; // Chemin mis à jour pour l'importation du logo
import { motion } from 'framer-motion';

const AccountValidationPage = () => {
  let navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <img src={logo} alt="Logo" style={{ height: '25px', marginBottom: '20px' }} />
      <Box sx={{ border:'1px solid #ddd', textAlign:'center', p:3, borderRadius:'4px' }}>
      <Typography variant="h5" gutterBottom>
        Validate Your Account
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Please check your email to validate your account.
      </Typography>
      <Button variant="contained" onClick={() => navigate('/gallery')}>
        Go to login
      </Button>
      </Box>
    </Box>
    </motion.div>
  );
};

export default AccountValidationPage;
