import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ELIGIBLE_SHIPPING_METHODS, GET_ACTIVE_ORDER_DETAILS } from '../../graphql/queries';
import { ASSIGN_SHIPPING_METHOD_TO_ORDER } from '../../graphql/mutations';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CircularProgress,
  Radio
} from '@mui/material';

const ShippingPage = ({ onShippingMethodChange, onNext }) => {
  const { data: shippingData, loading: shippingLoading } = useQuery(GET_ELIGIBLE_SHIPPING_METHODS);
  const { data: orderData, loading: orderLoading } = useQuery(GET_ACTIVE_ORDER_DETAILS, {
    fetchPolicy: "network-only"  // Toujours récupérer les données depuis le serveur
  });  const [assignShippingMethodToOrder] = useMutation(ASSIGN_SHIPPING_METHOD_TO_ORDER);

  const [selectedShippingMethod, setSelectedShippingMethod] = useState('');

  useEffect(() => {
    if (!orderLoading && orderData && orderData.activeOrder && orderData.activeOrder.shippingLines.length > 0) {
      const activeMethodId = orderData.activeOrder.shippingLines[0].shippingMethod.id;
      // Réinitialiser uniquement si selectedShippingMethod n'a pas encore été défini
      if (!selectedShippingMethod) {
        setSelectedShippingMethod(activeMethodId);
        onShippingMethodChange(activeMethodId);
      }
    }
  }, [orderData, orderLoading]); // Retirer selectedShippingMethod et onShippingMethodChange des dépendances

  const handleSelectShippingMethod = (methodId) => {
    setSelectedShippingMethod(methodId);
    onShippingMethodChange(methodId);
  };

  const handleContinue = async () => {
    try {
      await assignShippingMethodToOrder({
        variables: {
          id: selectedShippingMethod
        }
      });
      onNext();
    } catch (error) {
      console.error("Failed to proceed:", error);
    }
  };

  if (shippingLoading) return <CircularProgress />;
  if (!shippingData) return <Typography color="error">Failed to load shipping methods.</Typography>;

  return (
    <Box>
      <Typography  variant="h4" gutterBottom>
        Select Shipping Method
      </Typography>
      {shippingData.eligibleShippingMethods.map((method) => (
        <Card elevation={0} key={method.id} sx={{ mt: 2, border:'1px solid #ddd', borderRadius:'4px' }}>
          <CardActionArea onClick={() => handleSelectShippingMethod(method.id)}>
            <CardContent>
              <Typography variant="h6">{method.name}</Typography>
              <Radio
                checked={selectedShippingMethod === method.id}
                onChange={() => handleSelectShippingMethod(method.id)}
                value={method.id}
                name="shipping-method-radio"
                inputProps={{ 'aria-label': method.name }}
              />
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
      <Button
        variant="contained"
        fullWidth
        color="primary"
        sx={{ mt: 2, mb: 0.5 }}
        onClick={handleContinue}
        disabled={!selectedShippingMethod}
      >
        Continue
      </Button>
    </Box>
  );
};

export default ShippingPage;
