import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2231E9',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
  typography: {
    fontFamily: "'Manrope', sans-serif",
    h1: {
      fontFamily: "'Ranade', sans-serif",
    },
    h2: {
      fontFamily: "'Ranade', sans-serif",
    },
    h3: {
      fontFamily: "'Ranade', sans-serif",
    },
    h4: {
      fontFamily: "'Ranade', sans-serif",
    },
    h5: {
      fontFamily: "'Ranade', sans-serif",
    },
    h6: {
      fontFamily: "'Ranade', sans-serif",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        p {
          margin-block-start: 0;
          margin-block-end: 0;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // Supprime l'ombre par défaut
          textTransform: 'none', // Garde la casse du texte comme défini
          letterSpacing: '0.5px', // Espacement léger des lettres
          padding: '8px 30px', // Padding personnalisé
          // Supprimer l'ombre au survol
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
});

export default theme;
