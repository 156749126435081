import { gql } from '@apollo/client'
import {
    ASSETS_FRAGMENT,
    CART_FRAGMENT,
    SEARCH_RESULT_FRAGMENT,
    ORDER_LIST_FRAGMENT,
} from './fragments'

export const ALL_PRODUCTS = gql`
    query allProducts {
        products {
            items {
                id
                name
                description
                assets {
                    id
                    name
                    preview
                    source
                }
                variants {
                    name
                    sku
                    price
                    productId
                    stockLevel
                }
                facetValues {
                    name
                    facet {
                        name
                    }
                }
            }
        }
    }
`
export const ONE_PRODUCT = gql`
    query oneProduct($slug: String) {
        product(slug: $slug) {
            id
            name
            description
            variants {
                id
                name
                options {
                    code
                    name
                    __typename
                }
                price
                priceWithTax
                sku
                __typename
            }
            featuredAsset {
                ...Asset
                __typename
            }
            assets {
                ...Asset
                __typename
            }
            collections {
                id
                slug
                breadcrumbs {
                    id
                    name
                    slug
                    __typename
                }
                __typename
            }
            __typename
        }
    }
    ${ASSETS_FRAGMENT}
`

export const USER_ACTIVE = gql`
    query me {
        me {
            id
            identifier
            channels {
                id
                token
                code
            }
        }
    }
`

export const CUSTOMER_ACTIVE = gql`
    query customerActive {
        activeCustomer {
            id
            firstName
            lastName
            phoneNumber
            emailAddress
            addresses {
                fullName
                company
                streetLine1
                streetLine2
                city
                province
                postalCode
                country {
                    name
                }
                defaultShippingAddress
                defaultBillingAddress
                customFields
            }
            orders {
                items {
                    state
                    active
                    total
                    orderPlacedAt
                }
                totalItems
            }
            user {
                id
                verified
                lastLogin
                identifier
            }
        }
    }
`

export const GET_CUSTOMER_ADDRESSES = gql`
  query GetCustomerAddresses {
    activeCustomer {
      id
      addresses {
        id
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country {
          code
          name
        }
        phoneNumber
        defaultShippingAddress
        defaultBillingAddress
      }
    }
  }
`

export const ORDER_ACTIVE = gql`
    query orderActive {
        activeOrder {
            ...Cart
            shippingAddress {
                streetLine1
                streetLine2
                city
                province
                postalCode
                country
                phoneNumber
            }
            __typename
        }
    }
    ${CART_FRAGMENT}
`
export const ALL_COLLECTIONS = gql`
    {
        collections {
            items {
                id
                name
                slug
                parent {
                    id
                    name
                    slug
                }
                featuredAsset {
                    preview
                }
                assets {
                    name
                    source
                    preview
                }
                productVariants {
                    totalItems
                }
            }
            totalItems
        }
    }
`
export const ORDER_SHIPPING_METHODS = gql`
    {
        eligibleShippingMethods {
            id
            price
            code
            name
            description
            metadata
        }
    }
`
export const ORDER_PAYMENT_METHODS = gql`
    {
        eligiblePaymentMethods {
            id
            code
            name
            description
            isEligible
            eligibilityMessage
        }
    }
`
export const NEXT_STATES_ORDER = gql`
    {
        nextOrderStates
    }
`
export const SEARCH_PRODUCTS = gql`
    query SearchProducts($input: SearchInput!) {
        search(input: $input) {
            totalItems
            items {
                ...SearchResult
            }
            facetValues {
                count
                facetValue {
                    id
                    name
                    facet {
                        id
                        name
                    }
                }
            }
        }
    }
    ${SEARCH_RESULT_FRAGMENT}
`
export const ACTIVE_CUSTOMER = gql`
    query activeCustomer {
        activeCustomer {
            id
            createdAt
            title
            firstName
            lastName
            phoneNumber
            emailAddress
            addresses {
                fullName
                streetLine1
                streetLine2
                city
                province
                postalCode
                country {
                    code
                    name
                }
                phoneNumber
            }
            orders(options: { sort: { createdAt: DESC } }) {
                ...OrderList
            }
            user {
                id
                identifier
                verified
                lastLogin
            }
        }
    }
    ${ORDER_LIST_FRAGMENT}
`
export const ORDER_BY_ID = gql`
query getOrder($id: ID!) {
    order(id: $id) {
        id
        lines {
            productVariant {
                name
                price
                product {
                    id
                    name
                    assets {
                        id
                        preview
                      }
                  }
            }
            quantity
            linePrice
        }
        totalWithTax
        surcharges {
            description
            sku
            taxLines {
                description
                taxRate
            }
            price
            priceWithTax
            taxRate
        }
        fulfillments {
            createdAt
            state
            method
            trackingCode
        }
        history {
            items {
                type
                data
            }
            totalItems
        }
        createdAt
        orderPlacedAt
    }
}
`

export const GET_ELIGIBLE_SHIPPING_METHODS = gql`
  query GetEligibleShippingMethods {
    eligibleShippingMethods {
      id
      name
      description
      priceWithTax
    }
  }
`

export const GET_ACTIVE_ORDER_ID = gql`
  query GetActiveOrderId {
    activeOrder {
      id
    }
  }
`

export const SET_SHIPPING_METHOD = gql`
  mutation SetShippingMethod($orderCode: String!, $shippingMethodId: ID!) {
    setOrderShippingMethod(orderCode: $orderCode, shippingMethodId: $shippingMethodId) {
      success
      errorCode
      message
      order {
        id
        code
        state
        total
        shippingLines {
          shippingMethod {
            id
            name
            priceWithTax
          }
        }
      }
    }
  }
`

export const GET_ACTIVE_ORDER = gql`
query GetActiveOrder {
    activeOrder {
      id
      code
      state
      total
      currencyCode
      shipping
      subTotal
      totalQuantity
      lines {
        id
        quantity
        linePrice
        productVariant {
          id
          name
          sku
          price
          product {
            id
            name
            assets {
                id
                preview
              }
          }
        }
      }
    }
  }  
`

export const GET_ACTIVE_ORDER_DETAILS = gql`
query GetActiveOrderDetails {
    activeOrder {
      id
      shippingAddress {
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        countryCode
        phoneNumber
      }
      shippingLines {
        shippingMethod {
          id
          name
          description
        }
      }
    }
  }  
`

export const ORDERS_BY_CUSTOMER = gql`
  query OrdersByCustomer {
    activeCustomer {
      orders {
        items {
          id
          code
          state
          totalWithTax
          currencyCode
          lines {  
            productVariant {
              id
              name
              priceWithTax
            }
            quantity
          }
          createdAt
        }
        totalItems
      }
    }
  }
`
export const GET_PAYMENT_METHODS = gql`
    query GetPaymentMethods {
        eligiblePaymentMethods {
        id
        name
        code
        isEligible
        }
    }
`

export const GENERATE_TOKEN = gql`
  query GenerateBraintreeClientToken {
    generateBraintreeClientToken
  }
`