import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Modal,
  Drawer,
  useMediaQuery,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { CUSTOMER_ACTIVE, ORDERS_BY_CUSTOMER } from '../../graphql/queries';
import { USER_LOGOUT } from '../../graphql/mutations';
import LogoBar from '../LogoBar';
import Footer from '../Footer';
import RequestPasswordComponent from '../User/RequestPasswordComponent';
import { motion } from 'framer-motion';

const ProfilePage = () => {
  const { user, setUser } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [visibleOrders, setVisibleOrders] = useState(3);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  const { data, loading, error } = useQuery(CUSTOMER_ACTIVE, {
    variables: { id: user?.id },
    skip: !user,
  });

  const { data: ordersData, fetchMore } = useQuery(ORDERS_BY_CUSTOMER, {
    variables: { customerId: user?.id },
    skip: !user,
  });

  const [logout] = useMutation(USER_LOGOUT, {
    onCompleted: () => {
      setUser(null);
    },
  });

  const handlePasswordResetToggle = () => {
    setOpen(!open);
  };

  const handleLoadMoreOrders = () => {
    setVisibleOrders(visibleOrders + 3);
    fetchMore({
      variables: {
        skip: visibleOrders,
        limit: 3,
      },
    });
  };

  const passwordResetContent = (
    <RequestPasswordComponent onClose={handlePasswordResetToggle} />
  );

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  ); 
  if (error) return <div>Error loading order details</div>;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <Helmet>
        <title>KAJIKA - Account</title>
        <meta name="description" content="Account page." />
      </Helmet>
<Box>
  <LogoBar />
  <Grid container spacing={2} sx={{ p: 2, pt: { xs: '0', sm: '2' }, minHeight: '74vh', mt: { xs: '0vh', sm: '4vh' } }}>
    {/* Colonne pour les détails du compte */}
    <Grid item xs={12} md={3} sx={{ order: { xs: 1, md: 2 } }}>
      <Card elevation={0} sx={{ mt: 5, p:3, border: '1px solid #ddd' }}>
        <Typography variant="h6" gutterBottom>
          Account Details
        </Typography>
        {data && data.activeCustomer ? (
          <>
            <Typography variant="body1">{`Name: ${data.activeCustomer.firstName} ${data.activeCustomer.lastName}`}</Typography>
            <Typography variant="body1">{`Email: ${data.activeCustomer.emailAddress}`}</Typography>
            <Button variant="contained" onClick={handlePasswordResetToggle} sx={{ mt: 2, mr:1 }}>Reset Password</Button>
            <Button variant="outlined" onClick={() => logout()} color="error" sx={{ mt: 2 }}>Logout</Button>
          </>
        ) : (
          <Typography variant="body1">Account details not found.</Typography>
        )}
      </Card>
    </Grid>

{/* Colonne pour l'historique des commandes */}
<Grid item xs={12} md={9} sx={{ order: { xs: 2, md: 1 }, mt: { xs: 4, md: 0 } }}>
  <Typography variant="h5" gutterBottom>
    Your Orders
  </Typography>
  {ordersData && ordersData.activeCustomer && ordersData.activeCustomer.orders.items.length > 0 ? (
  ordersData.activeCustomer.orders.items.filter(order => order.state !== "AddingItems").length > 0 ? (
    ordersData.activeCustomer.orders.items
      .filter(order => order.state !== "AddingItems")
      .slice(0, visibleOrders)
      .reverse()
      .map((order) => (
        <Card key={order.id} elevation={0} sx={{ mb: 2, border: '1px solid #ddd', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
          <Box>
            <Typography variant="body">{`Order #${order.code}`}</Typography>
            <Typography variant="body2" color="textSecondary">{`Placed on: ${new Date(order.createdAt).toLocaleDateString()}`}</Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>{`State: ${order.state}`}</Typography>
          </Box>
          <Typography variant="body">
            {`${(order.totalWithTax / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}`}
          </Typography>
        </Card>
      ))
  ) : (
    <Typography variant="body1">You have no orders.</Typography>
  )
) : (
  <Typography variant="body1">You have no orders.</Typography>
)}

  {ordersData && ordersData.activeCustomer && ordersData.activeCustomer.orders.items.length > visibleOrders && (
    <Button variant="outlined" onClick={handleLoadMoreOrders}>Load More</Button>
  )}
</Grid>


  </Grid>
  {isMobile ? (
    <Drawer anchor="bottom" open={open} onClose={handlePasswordResetToggle}>
      {passwordResetContent}
    </Drawer>
  ) : (
    <Modal open={open} onClose={handlePasswordResetToggle}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        {passwordResetContent}
      </Box>
    </Modal>
  )}
  <Footer />
</Box>
</motion.div>
  );
};

export default ProfilePage;
