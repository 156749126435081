import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Container, styled, Dialog, DialogContent, SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material';
import Footer from './Footer'; 
import LogoBar from './LogoBar'; 
import { motion } from 'framer-motion';
import flowersIcon from '../img/flowers.svg';

// Composant pour gérer l'animation et la traduction des mots
const Word = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  transition: 'opacity 0.5s',
  '&:hover': {
    opacity: 0,
  },
  '&:hover::after': {
    content: 'attr(data-hover)',
    opacity: 0.5,
    transition: 'opacity 0.5s',
  }
}));

const initialWords = [
  { text: "Kajika", translated: "河鹿" },
  { text: "Aki", translated: "安芸" },
];

const AboutPage = () => {
  const [words, setWords] = useState(initialWords);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMouseOver = (index) => {
    const newWords = [...words];
    newWords[index].text = newWords[index].translated; // Remplace le mot par sa traduction
    setWords(newWords);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <Helmet>
        <title>KAJIKA - About</title>
        <meta name="description" content="Learn more about me and my journey as an artist." />
      </Helmet>
      <LogoBar />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(90vh - 170px)', pt:'5vh', pb:'5vh' }}>
        <Container maxWidth="md" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
          <Typography variant="body1" sx={{ lineHeight: 2 }}>
            {words.map((word, index) => (
              <Word
                key={index}
                data-hover={word.translated}
                onMouseOver={() => handleMouseOver(index)}
              >
                {word.text}{' '}
              </Word>
            ))}
            Ferrazzini est née en 1993 à Paris. Après avoir obtenu un diplôme des Métiers d'Arts et du Design à l’école Estienne en 2016, elle commence un Master CRFA (Concepteur et Réalisateur de films d’animation) à l’école des Gobelins qu’elle arrête en 2017 pour se consacrer à son premier court-métrage MOM. Produit en solo dans une période d’un an, MOM a gagné le prix du Meilleur film au Festival International d’Animation de Tbilisi (Georgie, 2021), a obtenu une Mention spéciale au Future Film Festival (Italie, 2021), au Anima - Best of shorts 2022 (Belgique), et a fait partie de la sélection officielle du Festival du film International d’Annecy en 2021. 
            <br></br><br></br>
            En 2019 et 2020, elle réalise The life and the death of the little mermaid et IMIEAMORI, deux oeuvres picturales de 1m50 sur 10m de long, réalisées entièrement sur papier, sans esquisse préalable, à l’encre de chine et aquarelle. 
            <br></br><br></br>
            Ses oeuvres sont exposées du 16 mars 2022 au 16 mai 2022 à la Folie Barbizon, sous l’œil bienveillant de Lionel Bensemoun : « Kajika embrasse la nature humaine en dévoilant sa propre intimité, ses personnages oscillent entre les émotions violentes et douces. Ils pleurent, vomissent, tuent ; ils rient, embrassent, aiment, et fleurissent aussi, dans une constante recherche d'harmonie au sein du chaos, car pour elle c’est au sein des plus grands drames que l'être humain peut trouver l'opportunité de laisser s'épanouir en lui les plus douces fleurs d'éternité . » 
            <br></br>
            <Box sx={{ borderRadius: '50%', backgroundColor: '#fff', border: '1px solid #eee', padding: '10px 15px 7px 15px', width:'fit-content', margin:'20px auto 0'  }}>
              <img 
                src={flowersIcon} 
                alt="Flowers Icon" 
                style={{ cursor: 'pointer', height:'45px'}} 
                onClick={handleClickOpen} 
              />
            </Box>
            {isMobile ? (
              <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={handleClose}
                onOpen={handleClickOpen}
              >
                <Box sx={{ p: 3 }}>
                  <Typography variant="body1" sx={{ lineHeight: 2, textAlign:'center' }}>
                    La fleur bleue, ou fleur d’éternité, ou fleur de joie est une fleur formée de 5 branches avec 5 pétales, elle a en son centre un cœur en étoile.
                    <br></br><br></br>
                    Elle représente la constante au sein du chaos, ce qui est toujours soi et qui ne change pas au sein de l’altérité.
                    <br></br><br></br>
                    Elle est un espace vide où vient se poser la couleur pour parler du flux.
                    <br></br><br></br>
                    La fleur bleue, ou fleur d’éternité, ou fleur de joie est la constante de Kajika. Un langage qui dépeint la matière au travers d’un champs. Un champs de fleurs, d’étoiles, et d’êtres. Elle est la justesse au niveau du langage, un langage qui se veut muet mais précis.
                    <br></br><br></br>
                    La fleur bleue de joie peinte en outremer doux et cyan tyran dépeint l’amour inconditionnel et la mort du corps.
                  </Typography>
                </Box>
              </SwipeableDrawer>
            ) : (
              <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogContent sx={{ p:6 }}>
                  <Typography variant="body1" sx={{ lineHeight: 2, textAlign:'center' }}>
                    La fleur bleue, ou fleur d’éternité, ou fleur de joie est une fleur formée de 5 branches avec 5 pétales, elle a en son centre un cœur en étoile.
                    <br></br><br></br>
                    Elle représente la constante au sein du chaos, ce qui est toujours soi et qui ne change pas au sein de l’altérité.
                    <br></br><br></br>
                    Elle est un espace vide où vient se poser la couleur pour parler du flux.
                    <br></br><br></br>
                    La fleur bleue, ou fleur d’éternité, ou fleur de joie est la constante de Kajika. Un langage qui dépeint la matière au travers d’un champs. Un champs de fleurs, d’étoiles, et d’êtres. Elle est la justesse au niveau du langage, un langage qui se veut muet mais précis.
                    <br></br><br></br>
                    La fleur bleue de joie peinte en outremer doux et cyan tyran dépeint l’amour inconditionnel et la mort du corps.
                  </Typography>
                </DialogContent>
              </Dialog>
            )}
          </Typography>
        </Container>
      </Box>
      <Footer />
    </motion.div>
  );
};

export default AboutPage;
