import React, { useState } from 'react';
import { Box, IconButton, Typography, Link, Dialog } from '@mui/material';
import InstagramIcon from '../img/instagram.svg';
import VimeoIcon from '../img/vimeo.svg';
import MailIcon from '../img/mail.svg';
import ContactForm from './ContactForm';

function Footer() {
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);

  const toggleContactDialog = (open) => () => {
    setIsContactDialogOpen(open);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 20px 20px',
      mt: 'auto',
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '5px',
      }}>
        <IconButton aria-label="instagram" component="a" href="https://www.instagram.com" target="_blank" color="inherit">
          <img height="30" src={InstagramIcon} alt="Instagram" />
        </IconButton>
        <IconButton aria-label="vimeo" sx={{ mr: 1, ml: 1 }} component="a" href="https://www.vimeo.com" target="_blank" color="inherit">
          <img height="35" src={VimeoIcon} alt="Vimeo" />
        </IconButton>
        <IconButton aria-label="mail" onClick={toggleContactDialog(true)} color="inherit">
          <img height="20" src={MailIcon} alt="Mail" />
        </IconButton>
      </Box>
      <Typography sx={{ fontFamily: 'Manrope', fontWeight: 'bold' }} variant="body2" align="center">
        © 2024 Kajika Aki - <Link href="/terms" color="inherit" underline="hover">Terms of service</Link>
      </Typography>
      <Dialog
        open={isContactDialogOpen}
        onClose={toggleContactDialog(false)}
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{
          sx: {
            paddingY: 2,
            paddingX: 3,
          },
        }}
      >
        <ContactForm onSubmitSuccess={toggleContactDialog(false)} />
      </Dialog>
    </Box>
  );
}

export default Footer;
