import React, { useState } from 'react';
import { Box, Fab, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { motion } from 'framer-motion';
import MonstersBar from '../MonstersBar';

const LittlemermaidPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [imageLoaded, setImageLoaded] = useState(false);  // État pour suivre si l'image est chargée

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <MonstersBar />
      <Box display="flex" justifyContent="center" alignItems="center" style={{ height:'100vh', width: '100vw', overflow: 'hidden', cursor:'move' }}>
        {!imageLoaded && (
          <Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
            <CircularProgress />
          </Box>
        )}
        <TransformWrapper
          defaultScale={1}
          defaultPositionX={0}
          defaultPositionY={0}
          wheel={{
            step: 0.5,
          }}
        >
          {({ zoomIn, zoomOut }) => (
            <>
              <TransformComponent>
                <img 
                  src="/monsters/bw-large.jpg" 
                  alt="Large Colorful Monster" 
                  style={{ height: '100vh', display: imageLoaded ? 'block' : 'none' }} 
                  onLoad={() => setImageLoaded(true)}  // Gestionnaire onLoad pour mettre à jour l'état lorsque l'image est chargée
                />
              </TransformComponent>
              {imageLoaded && (
                <Box position="absolute" bottom="20px" left="50%" zIndex="tooltip" sx={{ transform: 'translateX(-50%)', display: 'flex', gap: 1 }}>
                  <Fab sx={{ backgroundColor: 'white', boxShadow:'none' }} aria-label="zoom-out" onClick={() => zoomOut()}>
                    <RemoveIcon />
                  </Fab>
                  <Fab sx={{ backgroundColor: 'white', boxShadow:'none' }} aria-label="zoom-in" onClick={() => zoomIn()}>
                    <AddIcon />
                  </Fab>
                </Box>
              )}
            </>
          )}
        </TransformWrapper>
      </Box>
      </motion.div>
  );
};

export default LittlemermaidPage;
