import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const MustLoginPage = () => {
  let navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ height:'calc(100vh - 170px)' }}>
      <Box sx={{ border:'1px solid #ddd', textAlign:'center', p:3, borderRadius:'4px' }}>
      <Typography variant="h5" gutterBottom>
        Login to order
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
      You must be logged in to place an order.
      </Typography>
      <Button variant="contained" onClick={() => navigate('/login')}>
        Go to login
      </Button>
      </Box>
    </Box>
    </motion.div>
  );
};

export default MustLoginPage;
