import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import logo from '../../img/logo.svg'; // Assurez-vous du chemin d'accès au logo

// Assurez-vous que la mutation est correctement définie avec `gql`
const RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
        __typename
    }
}`;

function ResetPasswordPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const [password, setPassword] = useState('');
    const [resetPassword, { loading, data, error }] = useMutation(RESET_PASSWORD_MUTATION);

    const handleResetPassword = async () => {
        try {
            const response = await resetPassword({
                variables: { token, password }
            });
            if (response.data) {
                window.location.href = '/account'; // Redirect to login page after reset
            }
        } catch (err) {
            console.error('Failed to reset password:', err);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
            <img src={logo} alt="Logo" style={{ width: 150, marginBottom: 20 }} />
            <Box sx={{ textAlign:'center', border:'1px solid #ddd', borderRadius:'4px', p:4 }}>
            <Typography variant="h5">Set Your New Password</Typography>
            <TextField
                label="New Password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                margin="normal"
                required
                fullWidth
            />
            <Button variant="contained" fullWidth  onClick={handleResetPassword} disabled={loading} sx={{ mt: 1 }}>
                {loading ? <CircularProgress size={24} /> : "Reset Password"}
            </Button>
            {data && <Typography>Password reset successfully!</Typography>}
            {error && <Typography color="error">Error resetting password: {error.message}</Typography>}
            </Box>

        </Box>
    );
}

export default ResetPasswordPage;
