import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { UserContext } from '../../context/UserContext';
import { Container, Card, CardContent, TextField, Button, Typography, Link, Box, Snackbar, Alert, Grid } from '@mui/material';
import { USER_LOGIN } from '../../graphql/mutations'; 
import logo from '../../img/logo.svg';
import { motion } from 'framer-motion';

const LoginPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        if (user) {
            navigate('/account');
        }
    }, [user, navigate]);

    const [loginUser, { loading, error }] = useMutation(USER_LOGIN, {
        onCompleted: (data) => {
            switch (data.login.__typename) {
                case 'CurrentUser':
                    // User login successful, update user context and show success message
                    setUser(data.login);  // Mettre à jour le contexte avec les détails de l'utilisateur
                    setSnackbarMessage('Login successful! Redirecting...');
                    setSnackbarSeverity('success');
                    setOpenSnackbar(true);
                    setTimeout(() => {
                        navigate('/account', { replace: true });  // Modifier pour naviguer vers 'account'
                    }, 1500); // Wait for 1.5 seconds to show the message before navigating
                    break;
                case 'InvalidCredentialsError':
                case 'NativeAuthStrategyError':
                    // Handle login failure due to invalid credentials or other errors
                    setSnackbarSeverity('error');
                    setSnackbarMessage(data.login.message);
                    setOpenSnackbar(true);
                    break;
                case 'NotVerifiedError':
                    // Handle case where the user's email is not verified
                    setSnackbarSeverity('warning');
                    setSnackbarMessage('Your account has not been verified. Please check your email to verify your account.');
                    setOpenSnackbar(true);
                    break;
                default:
                    // Handle other types of errors or unknown responses
                    setSnackbarSeverity('error');
                    setSnackbarMessage('An unexpected error occurred.');
                    setOpenSnackbar(true);
            }
        }
    });
    
    

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        loginUser({
            variables: {
                user: formData.email,
                password: formData.password,
                rememberMe: true
            }
        });
    };

    return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.75 }}
        >
        <Container maxWidth="sm">
            <Grid onClick={() => navigate('/gallery')} item xs={12} textAlign="center" sx={{ mt:4, mb:1.5, cursor:'pointer' }}>
                <img src={logo} alt="Logo" style={{ height: '25px' }} />
            </Grid>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="80vh">
                <Card elevation={0} sx={{ border:'1px solid #ddd', borderRadius:'4px' }}>
                    <CardContent>
                        <Box display="flex" flexDirection="column" alignItems="center" my={2}>
                            <Typography variant="h5" component="h2" gutterBottom>
                                Sign in
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    required
                                    label="Email"
                                    name="email"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={formData.email}
                                />
                                <TextField
                                    required
                                    label="Password"
                                    type="password"
                                    name="password"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={formData.password}
                                />
                                <Link href="/account/request-password" variant="body2" display="block" textAlign="right" sx={{ mt: 1 }}>
                                    Forgot password?
                                </Link>
                                <Button type="submit" color="primary" variant="contained" fullWidth sx={{ mt: 2 }}>
                                    Login
                                </Button>
                                <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                                    Don't have an account? <Link href="/register">Sign up</Link>
                                </Typography>
                            </form>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
        </motion.div>
  );
};

export default LoginPage;
