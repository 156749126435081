import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ACTIVE_ORDER } from '../../graphql/mutations'; // Import pour la requête de l'ordre actif
import { GET_PAYMENT_METHODS } from '../../graphql/queries'; // Import pour les méthodes de paiement
import { TRANSITION_TO_STATE } from '../../graphql/mutations'; // Import pour la mutation de transition d'état
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  Modal,
  Drawer,
  useMediaQuery
} from '@mui/material';
import PaymentComponent from './PaymentComponent'; // Ajustez le chemin si nécessaire

export default function PaymentPage() {
  const { loading, data, error } = useQuery(GET_ACTIVE_ORDER);
  const {
    data: paymentMethodsData,
    loading: paymentMethodsLoading,
    error: paymentMethodsError
  } = useQuery(GET_PAYMENT_METHODS);
  const [transitionToState] = useMutation(TRANSITION_TO_STATE);
  const [openPayment, setOpenPayment] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (paymentMethodsData && !paymentMethodsLoading && !paymentMethodsError) {
      console.log('Available payment methods:', paymentMethodsData.eligiblePaymentMethods);
    }
  }, [paymentMethodsData, paymentMethodsLoading, paymentMethodsError]);

  const handleContinue = async () => {
    try {
      setOpenPayment(true);
      const transitionResult = await transitionToState({
        variables: { state: "ArrangingPayment" }
      });
      console.log('Transition result:', transitionResult);
    } catch (error) {
      console.error('Error processing payment:', error);
      setOpenPayment(false); // Ferme le modal/tiroir en cas d'erreur
    }
  };

  if (loading || paymentMethodsLoading) return <CircularProgress />;
  if (error || paymentMethodsError) return <Typography variant="body2" color="error">{error ? error.message : paymentMethodsError.message}</Typography>;

  const { activeOrder } = data;

  if (!activeOrder) return <Typography variant="body2" color="error">No active order found.</Typography>;

  const formatPrice = (price) => {
    const euroPrice = price / 100; // Convertit les centimes en euros
    return `${euroPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)|(\.\d\d)/g, '$&')}€`;
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Order Summary
      </Typography>
      {activeOrder.lines.map((line) => (
        <Card key={line.id} elevation={0} sx={{ display: 'flex', mb: 2, p: 1, border: '1px solid #ddd' }}>
          <CardMedia
            component="img"
            sx={{ width: 151, borderRadius: '4px' }}
            image={line.productVariant.product.featuredAsset?.preview || "https://via.placeholder.com/150"}
            alt={`Image of ${line.productVariant.name}`}
          />
          <CardContent sx={{ flex: '1 0 auto', ml: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
              {line.productVariant.name}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'left' }}>
              Quantity: {line.quantity}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'left' }}>
              Price: {formatPrice(line.productVariant.priceWithTax)}
            </Typography>
          </CardContent>
        </Card>
      ))}
      <Typography variant="subtitle1">
        Total: {formatPrice(activeOrder.totalWithTax)}
      </Typography>
      <Button
        variant="contained"
        fullWidth
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleContinue}
      >
        Proceed to Payment
      </Button>
      {isMobile ? (
      <Drawer anchor="bottom" open={openPayment} onClose={() => setOpenPayment(false)} PaperProps={{ style: { padding: '30px 20px', minHeight: '40vh', display:'flex', justifyContent:'center' } }}>
        <PaymentComponent order={activeOrder} />
      </Drawer>
      ) : (
        <Modal open={openPayment} onClose={() => setOpenPayment(false)}>
          <Box sx={{  borderRadius:'4px', overflow:'hidden', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <PaymentComponent order={activeOrder} />
          </Box>
        </Modal>
      )}
    </Box>
  );
}
