import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import LogoBar from '../LogoBar';
import { motion } from 'framer-motion';


const MonstersPage = () => {
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>KAJIKA - Monsters</title>
        <meta name="description" content="Dive into the heart of my two giant frescoes." />
      </Helmet>
      <LogoBar />
      {/* Le style du conteneur est ajusté pour prendre toute la hauteur moins le header et le footer */}
      <Container component="main" sx={{ minHeight: 'calc(100vh - 170px)', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box sx={{ width: '100%', textAlign: 'center' }}>

          {/* Lien et image pour la fresque colorée */}
          <Link to="/monsters/imieiamori">
            <img
              src="/monsters/color.jpg"
              alt="IMIEIAMORI"
              style={{ maxWidth: '100%', borderRadius: '4px', overflow: 'hidden' }}
            />
          <Typography variant="h5" sx={{ mt: 2, mb: 0 }} gutterBottom>Imieiamori</Typography>
          <Typography variant="subtitle1" gutterBottom>1.5x10m, Ink and Watercolor on Paper</Typography>
          </Link>

          {/* Lien et image pour la fresque en noir et blanc */}
          <Link to="/monsters/littlemermaid">
            <img
              src="/monsters/bw.jpg"
              alt="THE LIFE AND DEATH OF THE LITTLE MERMAID"
              style={{ maxWidth: '100%', marginTop: '6vh', borderRadius: '4px', overflow: 'hidden' }}
            />
          <Typography variant="h5" sx={{ mt: 2, mb: 0 }} gutterBottom>The life and death of the little mermaid</Typography>
          <Typography variant="subtitle1" gutterBottom>1.5x10m, Ink on Paper</Typography>
          </Link>
        </Box>
      </Container>
      <Footer />
      </motion.div>
  );
};

export default MonstersPage;
